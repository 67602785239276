import React, {Fragment, useContext, useMemo} from 'react';
import styled from 'styled-components';
import CustomNav from '../../Components/CustomNav';
import {ChevronLeft} from '../../Components/SvgIcon';
import {Breakpoints, NavActions} from '../../Contexts/AppContext';
import {HelpMenu} from './HelpMenu';
import {HELP_LIST_CONFIG} from '../../Domain/Help';
import {useDimension} from '../../Hooks/AppHooks';
import {Color} from '../../Components/Widget';
import * as L from '../../Utils/Lang';
import {Context} from '../../Contexts/AppContext';
import {brandNameToBrandId, brandsDisplayArr} from '../../Domain/Brand';

export const MEMBER_CENTER_CONFIG = {
  route: '/profile?active=personal_info',
  key: 'member-center',
  title: {zh: '會員中心', en: '會員中心'},
  items: [
    {
      key: '/profile?active=credits',
      route: '/profile?active=credits',
      title: {zh: '購物金', en: '購物金'},
    },
    {
      key: '/profile?active=member_level',
      route: '/profile?active=member_level',
      title: {zh: '會員等級 / 權益', en: '會員等級 / 權益'},
    },
    {
      key: '/profile?active=orders',
      route: '/profile?active=orders',
      title: {zh: '購買記錄', en: '購買記錄'},
    },
    // FIXME : toggle off first
    // {
    //   key: '/profile?active=maintain',
    //   route: '/profile?active=maintain',
    //   title: {zh: '維修服務', en: '維修服務'},
    // },
    {
      key: '/profile?active=personal_info',
      route: '/profile?active=personal_info',
      title: {zh: '帳戶設定', en: '帳戶設定'},
    },
  ],
};

const SiteMapColumn = props => {
  const {config} = props;
  return (
    <div className="col">
      <div
        className="col-title"
        onClick={() => {
          if (config.disabled) {
            return;
          }
          NavActions.navigate(config.route);
        }}>
        {L.d(config.title)}
      </div>
      {config.items.map((i, idx) => (
        <div
          className="col-link"
          key={idx}
          onClick={() => {
            NavActions.navigate(i.route);
          }}>
          {L.d(i.title)}
        </div>
      ))}
    </div>
  );
};

const SiteMapPage = props => {
  const app = useContext(Context);
  const {pathname} = props.location;
  const {desktop} = useDimension();

  const CONFIGS = useMemo(() => {
    if (!app.brandCats) {
      return;
    }
    return Object.keys(app.brandCats).reduce((acc, cur) => {
      acc[cur] = app.brandCats[cur].map(cat => ({
        title: cat.title,
        route: '',
        key: cat.id,
        disabled: true,
        items: cat.collections.map(c => ({
          title: c.title,
          key: c.id,
          route: `/products?collection_id=${c.id}&brand=${cur}`,
        })),
      }));
      acc[cur].specialItems = [
        {
          title: {zh: '門市據點', en: '門市據點'},
          key: `/stores?brand=${cur}`,
          route: `/stores?brand=${cur}`,
          disabled: false,
        },
        {
          title: {zh: 'About the Brand', en: 'About the Brand'},
          key: `/aboutTheBrand?brand=${cur}`,
          route: `/aboutTheBrand?brand=${cur}`,
          disabled: false,
        },
        {
          title: {zh: '最新消息', en: '最新消息'},
          key: `/news?brand=${cur}`,
          route: `/news?brand=${cur}`,
          disabled: false,
        },
      ];

      return acc;
    }, {});
  }, [app.brandCats]);

  return (
    <Fragment>
      {!desktop && (
        <CustomNav
          navType="solid"
          left={
            <ChevronLeft
              onClick={() => {
                NavActions.navigate('/home');
              }}
            />
          }
          middle={<div>網站地圖</div>}
        />
      )}
      <Wrapper>
        <HelpMenu pathname={pathname} />
        <div className="right">
          <div className="header">網站地圖</div>
          <section>
            <div className="title">TUN</div>
            <div className="content-container">
              <SiteMapColumn config={HELP_LIST_CONFIG} />
              <SiteMapColumn config={MEMBER_CENTER_CONFIG} />
              <SiteMapColumn
                config={{
                  route: '/aboutTheTun',
                  key: '/aboutTheTun',
                  title: '關於 TUN',
                  items: [],
                }}
              />
            </div>
          </section>
          {Object.keys(app.brandCats).map((b, index) => (
            <section key={index}>
              <div className="title">
                {brandsDisplayArr.find(bd => bd.key === b).display}
              </div>
              <div className="content-container">
                {CONFIGS[b].map((c, idx) => (
                  <SiteMapColumn key={idx} config={c} />
                ))}
                <div className="col">
                  {CONFIGS[b].specialItems.map((i, sidx) => (
                    <div
                      key={sidx}
                      className="col-title"
                      onClick={() => {
                        NavActions.navigate(i.route);
                      }}>
                      {L.d(i.title)}
                    </div>
                  ))}
                </div>
              </div>
            </section>
          ))}
        </div>
      </Wrapper>
    </Fragment>
  );
};

const Wrapper = styled.div`
  padding: 40px 50px;
  display: flex;
  & > .right {
    & > .header {
      font-size: 30px;
      font-weight: bold;
      line-height: 1.27;
      margin-bottom: 32px;
    }
    & > section {
      margin-bottom: 48px;
      min-width: 656px;
      & > .title {
        width: 100%;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        padding: 12px 0;
        border-bottom: 1px solid ${Color.mainDark_10};
        margin-bottom: 24px;
      }
      & > .content-container {
        display: flex;
        & > .col {
          flex: 1;
          & > .col-title {
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            margin-bottom: 24px;
            cursor: pointer;
          }
          & > .col-link {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            margin-bottom: 8px;
            cursor: pointer;
          }
        }
      }
    }
  }

  @media screen and (max-width: ${Breakpoints.tablet}px) {
    padding: 20px;
    & > .right {
      width: 100%;
      & > .header {
        display: none;
      }

      & > section {
        margin-bottom: 48px;
        width: 100%;
        min-width: unset;
        & > .title {
        }
        & > .content-container {
          flex-wrap: wrap;
          & > .col {
            min-width: 125px;
            margin-bottom: 24px;
            & > .col-title {
            }
            & > .col-link {
            }
          }
        }
      }
    }
  }
`;

export default SiteMapPage;
